<template>
  <div class="ast-page">
    <v-row justify="center" class="my-15">
      <v-col cols="11" sm="9" md="6">
        <v-card light class="ast-card card-wrap d-flex flex-column justify-center align-center">
          <h2 class="mt-15 primary--text">{{ $t('ast') }}</h2>

          <v-row style="width: 100%;" class="mt-10">
            <v-col cols="12" class="d-flex flex-column justify-center align-center">
              <div>AST {{ $t('holding') }}</div>
              <h2 class="primary--text">{{ balance }}</h2>
            </v-col>
          </v-row>

          <v-row style="width: 100%;" class="my-10">
            <v-col cols="12" sm="6" class="d-flex flex-column justify-center align-center mb-5">
              <div>TBT {{ $t('claimable') }}</div>
              <h2 class="primary--text">{{ claimAmount }}</h2>
              <btn :buttonText="`${$t('claim')} TBT`" :color="'primary'" :width="200" :isDisabled="claimAmount===0" @clickBtn="claim()"></btn>
            </v-col>
          </v-row>

          <div class="px-5 mb-10" style='width: 100%;'>
            <div class="can-click w-100 text-center" @click="$router.push({name: 'Home'})">{{ $t('backToIndex') }}</div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <loading :loadingShow="loadingShow" :content="loadingText"></loading>
  </div>
</template>
<script>
import loading from '@/components/loading.vue'
import btn from '@/components/btn.vue'
export default {
  name: 'ast',
  data (){
    return {
      balance: 0,
      claimAmount: 0,
      loadingShow: false,
      loadingText: '',
      timer: null,
    }
  },
  components:{
    btn,
    loading
  },
  methods:{
    async claim(){
      if (this.$store.state.chainId){
        if (this.claimAmount > 0){
          let result = await this.$ast.claim(this.$store.state.account)
          // console.log('result', result)
          if (result.txHash){
            this.$toasted.show(this.$t('txSend'))
            this.loadingShow = true
            this.timer = window.setInterval(async () => {
              await this.getAmount()
              if (this.claimAmount === 0) {
                window.clearInterval(this.timer)
                this.loadingShow = false
              }
            }, 1000)
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }else{
          this.$toasted.error(this.$t('noReward'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async getAmount(){
      let result = await this.$ast.getAmount(this.$store.state.account)
      this.balance = result.balance
      this.claimAmount = result.claimable
    },
  },
  async mounted(){
    // defi contract
    this.loadingText = 'waitGetData'
    this.loadingShow = true
    try{
      await this.getAmount()
      this.loadingShow = false
    }catch(error){
      console.log('error', error)
    }
  }
}
</script>
<style lang="scss">
.ast-page{
  .ast-card{
    padding: 0;
  }
}
</style>